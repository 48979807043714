// Auth
import React, { useEffect, useState } from 'react';
import styles from './Auth.module.sass';
import { useAuth } from 'hooks/useAuth';
import {
  API_RESPONSE_STATUS,
  AUTH_STATUS,
  AuthFormState,
  AuthFormType,
} from 'types/auth';
import { getAuthRedirect } from 'components/containers/AuthProtection';
import { useRouter } from 'next/router';
import ROUTES from 'config/routes';
import useToast from 'components/common/Toast';
import AuthForm from './AuthForm';
import BrandList from './BrandList';
import { getLocalImageUrl } from 'utils/image';

export interface AuthProps {
  initialFormType: AuthFormType;
}

const AuthView: React.FC<AuthProps> = ({ initialFormType }) => {
  const router = useRouter();

  const [authFormType, setAuthFormType] =
    useState<AuthFormType>(initialFormType);

  const {
    authState: { status: authStatus, brand },
    login,
    googleLogin,
    signup,
  } = useAuth();
  const { triggerToast } = useToast();
  const [authForm, setAuthForm] = useState<AuthFormState>({
    email: '',
    password: '',
  });
  const { email, password } = authForm;

  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && brand) {
      const redirect = getAuthRedirect() || ROUTES.ShowCase.getPath();
      if (!brand.firstCampaign) {
        // If brand has not created 1st campaign, push to Create Campaign Paywall
        router.push({
          pathname: ROUTES.CreateCampaign.getPath(),
        });
      } else {
        router.push({
          pathname: redirect,
          query: `latest_campaign`,
        });
      }
    }
  }, [authStatus, brand, router]);

  const handleGoogleSSO = async () => {
    const { status, message } = await googleLogin();
    if (status === API_RESPONSE_STATUS.ERROR) {
      // TODO: Set as auth error w/ useAuth hook
      //   setEmailError(message);
      triggerToast(message);
    }
  };

  const handleLogin = () => {
    login(email, password);
  };

  const handleSignUp = () => {
    signup(email, password);
  };

  return (
    <div className={styles.authView}>
      <div className={styles.leftColumn}>
        <img
          className={styles.logo}
          src={getLocalImageUrl('/images/trend-logo-black.svg')}
          alt="trend logo"
        />
        <BrandList />
        <div className={styles.contentImgWrapper}>
          <img
            className={styles.contentImg}
            src={getLocalImageUrl('/images/auth/sign_up.png')}
            alt=""
          />
        </div>
      </div>
      <div className={styles.authContainer}>
        <AuthForm
          authForm={authForm}
          authFormType={authFormType}
          setAuthForm={setAuthForm}
          setAuthFormType={setAuthFormType}
          onSignUp={handleSignUp}
          onLogin={handleLogin}
          onGoogleSSO={handleGoogleSSO}
        />
        <footer className={styles.footer}>
          <p>Are you a content creator?</p>
          <a href="https://creators.trend.io">
            apply to our creator network here
          </a>
        </footer>
      </div>
    </div>
  );
};

export default AuthView;
